import type { ChangeEvent } from "react";
import type React from "react";
import { useState, useRef } from "react";
import { t } from "ttag";

import {
  DeleteButton,
  InputContainer,
  UploadButton,
  Wrapper,
} from "./ChartSettingsTableHeaderUpload.styled";

type HeaderFile = { fileName: string; data: number[] };

interface Props {
  value: HeaderFile;
  onChange: (val: HeaderFile | null) => void;
}

export const ChartSettingsTableHeaderUpload: React.FC<Props> = ({
  value,
  onChange,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [isFileUploaded, setIsFileUploaded] = useState(Boolean(value) || false);
  const [fileName, setFileName] = useState(value?.fileName || null);

  const handleFileInputChange = async (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];

    if (!file) {
      return;
    }

    const arrayBufferFromFile = await file.arrayBuffer();
    const uint8Array = new Uint8Array(arrayBufferFromFile);
    const data = Array.from(uint8Array);

    setFileName(file.name);
    setIsFileUploaded(true);
    onChange({ ...value, fileName: file.name, data: data });
  };

  const handleRemoveFile = () => {
    onChange(null);
    setIsFileUploaded(false);
    setFileName(null);
    if (inputRef.current) {
      inputRef.current.value = "";
    }
  };

  return (
    <Wrapper>
      <InputContainer>
        {isFileUploaded && fileName && (
          <>
            <span>{fileName}</span>
            <DeleteButton onClick={handleRemoveFile}>X</DeleteButton>
          </>
        )}

        {!isFileUploaded && <span>{t`Upload the file` + " .xlsx"}</span>}
        <input
          type="file"
          accept=".xlsx"
          onChange={handleFileInputChange}
          ref={inputRef}
          style={{ display: "none" }}
        />
      </InputContainer>
      <UploadButton onClick={() => inputRef.current?.click()}>
        {t`Upload`}
      </UploadButton>
    </Wrapper>
  );
};
