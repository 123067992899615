import ExcelJS from "exceljs";
import type { ExcelDataType } from "xlsx-js-style";
import * as XLSX from "xlsx-js-style";

import { CELL_DEFAULT_INDENT } from "metabase/visualizations/components/TableInteractive/TableInteractive";
import type {
  TableSettingsValues,
  TableSpecialSettings,
} from "metabase/visualizations/lib/settings/tableSettings";
import type {
  DatasetColumn,
  DatasetData,
  VisualizationSettings,
} from "metabase-types/api";

import type { TVisualizationSettings } from "../downloading";

import type { XlsxCellObject } from "./excelDownloadingUtils";
import { createXlsxCellStyle, getTextWidth } from "./excelDownloadingUtils";

type ObjWithStrVals = Record<string, string>;

interface DownloadTable {
  fileName: string;
  file: Blob;
  type: string;
  visualizationSettings?: TableSettingsValues;
  data: DatasetData;
}

export function downloadTable({
  fileName,
  file,
  visualizationSettings,
  data,
}: DownloadTable) {
  const headerTableFile = visualizationSettings?.["table.table_header_export"];
  const columnsTypes = getColumnsTypes(data.cols);

  const fileType = file.type;

  const fileReader = new FileReader();
  fileReader.readAsArrayBuffer(file);

  fileReader.onload = async event => {
    const arrayBufferData = event.target?.result;

    if (!(arrayBufferData instanceof ArrayBuffer)) {
      return;
    }
    const workbook = getWorkBook(fileType, arrayBufferData);

    if (workbook) {
      if (!headerTableFile) {
        const updatedWorkBook = getUpdatedWorkBook({
          workbook,
          visualizationSettings,
          data,
        });
        XLSX.writeFile(updatedWorkBook, fileName);
      } else {
        const uint8Array = new Uint8Array(headerTableFile.data);
        const headerTableData = uint8Array.buffer;
        const headerWorkbook = new ExcelJS.Workbook();
        await headerWorkbook.xlsx
          .load(headerTableData)
          .then(() => {
            const headerWorksheet = headerWorkbook.worksheets[0];

            const DbWorksheet = workbook.Sheets[workbook.SheetNames[0]];
            const DbRowData = XLSX.utils.sheet_to_json(DbWorksheet, {
              header: 1,
            });

            DbRowData.forEach((row, rowIndex) => {
              if (Array.isArray(row) && rowIndex !== 0) {
                const formattedRow = headerWorksheet.addRow(row);
                const cellBorder: ExcelJS.Border = {
                  style: "thin",
                  color: { argb: "FF000000" },
                };

                formattedRow.eachCell(
                  { includeEmpty: true },
                  (cell, colNumber) => {
                    if (
                      columnsTypes[colNumber - 1] &&
                      columnsTypes[colNumber - 1].format !== null
                    ) {
                      cell.numFmt =
                        columnsTypes[colNumber - 1].format ?? "General";
                    } else {
                      cell.numFmt = "General";
                    }

                    cell.border = {
                      top: cellBorder,
                      left: cellBorder,
                      bottom: cellBorder,
                      right: cellBorder,
                    };
                  },
                );
              }
            });
            saveExcelFile(headerWorkbook, fileName);
          })
          .catch(() => {
            const updatedWorkBook = getUpdatedWorkBook({
              workbook,
              visualizationSettings,
              data,
            });
            XLSX.writeFile(updatedWorkBook, fileName);
          });
      }
    } else {
      downloadData(fileName, file);
    }
  };
}

interface GetUpdatedWorkBook {
  workbook: XLSX.WorkBook;
  visualizationSettings: TableSettingsValues | undefined;
  data: DatasetData;
}

function getUpdatedWorkBook({
  workbook,
  visualizationSettings,
  data,
}: GetUpdatedWorkBook) {
  const changedTitles = getChangeTitles(visualizationSettings);

  const { rows, cols } = data;
  const columnsTypes = getColumnsTypes(cols);

  workbook.SheetNames.forEach(sheetName => {
    const worksheet = workbook.Sheets[sheetName];

    if (!worksheet) {
      return;
    }

    const specialTableSettings = visualizationSettings as TableSettingsValues &
      TableSpecialSettings;

    const cellBackgroundColorGetter =
      specialTableSettings["table._cell_color_getter"];

    const cellTextColorGetter =
      specialTableSettings["table._text_color_getter"];

    const cellFontStyleGetter =
      specialTableSettings["table._cell_font_style_getter"];

    const refRange = worksheet["!ref"];
    if (refRange) {
      const rowsHeights: XLSX.RowInfo[] = [];

      const range = XLSX.utils.decode_range(refRange);

      const colsWidths = getTableColsWidths(visualizationSettings, range.e.c);

      for (let rowIndex = range.s.r; rowIndex <= range.e.r; rowIndex++) {
        const isTitleRow = rowIndex === 0;
        for (let colIndex = range.s.c; colIndex <= range.e.c; colIndex++) {
          const address = XLSX.utils.encode_col(colIndex) + "" + (rowIndex + 1);

          let cellData: XlsxCellObject | undefined = worksheet[address];
          if (!cellData) {
            cellData = {
              v: "",
              h: "",
              t: "s",
              w: "",
            };
            worksheet[address] = cellData;
          }

          if (isTitleRow) {
            const value = changedTitles[colIndex] || worksheet[address].v;

            if (!worksheet[address]) {
              continue;
            }

            const titleCellStyle = createXlsxCellStyle({
              fontColor: visualizationSettings?.["table.header_text_color"],
              isFontBold: visualizationSettings?.["table.title_font_bold"],
              isFontItalic: visualizationSettings?.["table.title_font_italic"],
              fontSize: visualizationSettings?.["table.title_font_size"],

              backgroundColor:
                visualizationSettings?.["table.header_background_color"],
              verticalAlignment:
                visualizationSettings?.["table.title_vertical_alignment"],
              horizontalAlignment:
                visualizationSettings?.["table.title_horizontal_alignment"],

              borderColor: visualizationSettings?.["table.grid_color"],
            });

            cellData.s = titleCellStyle;

            if (!colsWidths[colIndex]) {
              colsWidths[colIndex] = {
                wpx: getTextWidth(
                  String(value),
                  visualizationSettings?.["table.title_font_size"],
                ),
              };
            }
          }

          // body
          else {
            const indexOfDataRow = rowIndex - 1;
            const conditionalBackgroundColor = cellBackgroundColorGetter
              ? cellBackgroundColorGetter(
                  rows[indexOfDataRow],
                  indexOfDataRow,
                  cols[colIndex].name,
                )
              : null;

            const conditionalTextColor = cellTextColorGetter
              ? cellTextColorGetter(
                  rows[indexOfDataRow],
                  indexOfDataRow,
                  cols[colIndex].name,
                )
              : null;

            const conditionalFontStyle = cellFontStyleGetter
              ? cellFontStyleGetter(
                  rows[indexOfDataRow],
                  indexOfDataRow,
                  cols[colIndex].name,
                )
              : null;

            const settingsBackgroundColor =
              rowIndex % 2 === 0
                ? visualizationSettings?.["table.cell_even_background_color"]
                : visualizationSettings?.["table.cell_odd_background_color"];

            const bodyCellStyle = createXlsxCellStyle({
              fontColor:
                conditionalTextColor ||
                visualizationSettings?.["table.cell_text_color"],
              isFontBold:
                conditionalFontStyle?.font_bold ||
                visualizationSettings?.["table.cell_font_bold"],
              isFontItalic:
                conditionalFontStyle?.font_italic ||
                visualizationSettings?.["table.cell_font_italic"],
              fontSize: visualizationSettings?.["table.cell_font_size"],

              backgroundColor:
                conditionalBackgroundColor || settingsBackgroundColor,
              verticalAlignment:
                visualizationSettings?.["table.cell_vertical_alignment"],
              horizontalAlignment:
                visualizationSettings?.["table.cell_horizontal_alignment"],

              borderColor: visualizationSettings?.["table.grid_color"],
            });

            cellData.s = bodyCellStyle;
            cellData.t = columnsTypes[colIndex].columnType;
            cellData.t === "d"
              ? cellData.w !== ""
                ? (cellData.v = cellData.w)
                : (cellData.v = undefined)
              : null;
            columnsTypes[colIndex].format
              ? (cellData.z = columnsTypes[colIndex].format)
              : null;
          }
        }

        const cellIndentVertical = visualizationSettings?.[
          "table.cell_auto_indent"
        ]
          ? CELL_DEFAULT_INDENT
          : visualizationSettings?.["table.cell_indent_vertical"] ||
            CELL_DEFAULT_INDENT;
        const cellFontSize =
          visualizationSettings?.["table.cell_font_size"] || 12;

        const cellHeight = visualizationSettings?.["table.cell_auto_height"]
          ? cellFontSize + cellIndentVertical * 2
          : visualizationSettings?.["table.cell_height"];

        rowsHeights.push({
          hpx: isTitleRow
            ? visualizationSettings?.["table.title_height"]
            : cellHeight,
        });
      }
      worksheet["!rows"] = rowsHeights;
      worksheet["!cols"] = colsWidths as XLSX.ColInfo[];
    }
  });

  return workbook;
}

function getTableColsWidths(
  visualizationSettings: TVisualizationSettings | undefined,
  lastColIndex: number,
) {
  const columnWidthsSettings = visualizationSettings?.[
    "table.column_widths"
  ] as (number | null)[] | [];

  const colsWidths: (XLSX.ColInfo | null)[] = [];
  for (let i = 0; i <= lastColIndex; i++) {
    colsWidths.push(null);
  }

  columnWidthsSettings?.forEach((width, index) => {
    if (width) {
      colsWidths[index] = { wpx: width };
    }
  });

  return colsWidths;
}

function downloadData(fileName: string, data: Blob) {
  const url = URL.createObjectURL(data);
  const link = document.createElement("a");
  link.href = url;
  link.target = "_blank";
  link.download = "file";
  link.setAttribute("download", fileName);
  document.body.appendChild(link);
  link.click();

  URL.revokeObjectURL(url);
  link.remove();
}

function getWorkBook(fileType: string, arrayBufferData: ArrayBuffer) {
  if (
    fileType ===
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
  ) {
    return XLSX.read(arrayBufferData, { type: "buffer" });
  } else if (fileType === "text/csv") {
    const text = new TextDecoder().decode(arrayBufferData);
    return XLSX.read(text, { type: "string" });
  } else {
    return null;
  }
}

function getChangeTitles(
  visualizationSettings: VisualizationSettings | undefined,
) {
  const changedTitles: ObjWithStrVals = {};

  if (visualizationSettings) {
    const changedColumnsTitle: ObjWithStrVals = {};
    const columnSettings = visualizationSettings.column_settings;
    const columnsKeysWithSettingsList = Object.keys(columnSettings);

    columnsKeysWithSettingsList.forEach(columnName => {
      const columnTitle = columnSettings[columnName].column_title as string;
      const parsedColumnName = JSON.parse(columnName);
      const columnFieldRef = parsedColumnName[1][1];
      changedColumnsTitle[columnFieldRef] = columnTitle;
    });

    const originalColsList = visualizationSettings?.["table.columns"];

    if (originalColsList) {
      originalColsList.forEach((col, index) => {
        const colFieldRef = col.fieldRef && col.fieldRef[1];
        if (colFieldRef && changedColumnsTitle[colFieldRef]) {
          changedTitles[index] = changedColumnsTitle[colFieldRef];
        }
      });
    }
  }

  return changedTitles;
}

interface ColumnType {
  columnType: ExcelDataType;
  format?: string;
}

function getColumnsTypes(cols: DatasetColumn[]): ColumnType[] {
  const validTypes = ["type/Latitude", "type/Longitude"];
  return cols.map(item => {
    const initialValue = item["base_type"] || "type/Text";
    const cases = new Map<string, ColumnType>([
      ["type/Integer", { columnType: "n", format: "0" }],
      ["type/BigInteger", { columnType: "n", format: "0" }],
      [
        "type/Float",
        item["semantic_type"] && validTypes.includes(item["semantic_type"])
          ? { columnType: "s" }
          : { columnType: "n", format: "#,##0.00" },
      ],
      ["type/Text", { columnType: "s" }],
      ["type/DateTime", { columnType: "d", format: "dd mmmm yyyy hh:mm" }],
      ["type/Date", { columnType: "d", format: "dd mmmm yyyy" }],
      ["type/Boolean", { columnType: "b", format: "BOOLEAN" }],
    ]);
    const result = cases.get(initialValue);
    return result ? result : { columnType: "s" };
  });
}
const saveExcelFile = async (workbook: ExcelJS.Workbook, fileName: string) => {
  const buffer = await workbook.xlsx.writeBuffer();
  const blob = new Blob([buffer], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });
  const url = URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = url;
  link.download = fileName;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  URL.revokeObjectURL(url);
};
