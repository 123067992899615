import * as XLSX from "xlsx-js-style";

import { getTitles, multiLevelPivot } from "metabase/lib/data_grid";
import type { PivotTableSettingValues } from "metabase/visualizations/lib/settings/pivotTableSettings";
import type { WidgetsVisualizationSettings } from "metabase/visualizations/lib/settings/widgetsSettings";
import type { DatasetData, VisualizationSettings } from "metabase-types/api";

import { createXlsxCellStyle, getTextWidth } from "./excelDownloadingUtils";

type XlsxCellObject = {
  v: XLSX.CellObject["v"];
  s?: XLSX.CellStyle;
  t?: XLSX.CellObject["t"];
  z?: string;
} | null;

type DataList = XlsxCellObject[][];

type LeftHeaderItem = {
  depth: number;
  hasChildren: boolean;
  hasSubtotal: boolean;
  isCollapsed: boolean;
  maxDepthBelow: number;
  offset: number;
  path: string[];
  value: string;
  rawValue?: number;
  span: number;
  column?: { base_type: string };
};

type LeftHeaderCellData = {
  item: LeftHeaderItem;
  backgroundColor?: string;
  textColor?: string;
  fontStyle?: {
    font_bold?: boolean;
    font_italic?: boolean;
  };
};

type RowSectionElement = {
  value: string | number;
  rawValue: string | number;
  isSubtotal: boolean;
  isGrandTotal: boolean;
  backgroundColor?: string;
  textColor?: string;
  fontStyle?: {
    font_bold?: boolean;
    font_italic?: boolean;
  };
};

type TVisualizationSettings = VisualizationSettings &
  WidgetsVisualizationSettings;

interface DownloadPivotTable {
  data: DatasetData;
  visualizationSettings: PivotTableSettingValues | undefined;
  cardName: string;
}
export function downloadPivotTable({
  data,
  visualizationSettings,
  cardName,
}: DownloadPivotTable) {
  const dataOfPivotTableForExcel = getDataOfPivotTableForExcel({
    data,
    visualizationSettings,
  });

  if (dataOfPivotTableForExcel) {
    const { resultTable, tableMergeRange, rowsHeights, workbookColsWidths } =
      dataOfPivotTableForExcel;

    const workBook = getWorkBookByPivotTableData({
      data: resultTable,
      mergeRange: tableMergeRange,
      rowsHeights,
      workbookColsWidths,
    });

    const fileName = (
      cardName +
      "_pivot_" +
      new Date(Date.now()).toISOString() +
      ".xlsx"
    ).replaceAll(" ", "_");

    XLSX.writeFile(workBook, fileName);
  }
}

interface GetDataOfPivotTableForExcel {
  data: DatasetData;
  visualizationSettings: PivotTableSettingValues | undefined;
}

function getDataOfPivotTableForExcel({
  data,
  visualizationSettings,
}: GetDataOfPivotTableForExcel) {
  const pivoted = multiLevelPivot(data, visualizationSettings);

  if (!pivoted) {
    return null;
  }

  const {
    leftHeaderItems,
    topHeaderItems,
    rowCount,
    columnCount,
    rowIndex,
    getRowSection,
    getLeftSection,
    rowIndexes,
    columnIndexes,
    valueIndexes,
  } = pivoted;

  const topLeftCornerTitles = rowIndexes.map((rowIndex: number) =>
    getTitles(data, visualizationSettings, rowIndex),
  ) as string[];

  const topHeaderRows =
    columnIndexes.length + (valueIndexes.length > 1 ? 1 : 0) || 1;
  const leftHeadersColumnsCount = rowIndex[0].length;

  const { tableData, tableRowsHeights } = getTableData({
    rowCount,
    columnCount,
    getRowSection,
    visualizationSettings,
  });

  const { topTitlesList, topTitlesMergeRange, titlesRowsHeights } =
    getTopTitlesData({
      topHeaderItems,
      leftHeadersColumnsCount,
      columnCount,
      visualizationSettings,
    });

  const { leftTitlesList, leftTitlesMergeRange } = getLeftTitlesData({
    leftHeaderItems,
    topHeaderRows,
    topLeftCornerTitles,
    visualizationSettings,
    getLeftSection,
  });

  const resultTable = getFullTable({
    leftTitlesList,
    tableData,
    topTitlesList,
    topHeaderRows,
  });

  const tableMergeRange: XLSX.Range[] = [];
  tableMergeRange.push(...topTitlesMergeRange);
  tableMergeRange.push(...leftTitlesMergeRange);

  const rowsHeights: XLSX.RowInfo[] = [
    ...titlesRowsHeights,
    ...tableRowsHeights,
  ];

  const workbookColsWidths = getColsWidths({
    visualizationSettings,
    resultTable,
  });

  return { resultTable, tableMergeRange, rowsHeights, workbookColsWidths };
}

interface GetWorkBookByPivotTableData {
  data: DataList;
  mergeRange: XLSX.Range[];
  rowsHeights?: XLSX.RowInfo[];
  workbookColsWidths: XLSX.ColInfo[];
}

function getWorkBookByPivotTableData({
  data,
  mergeRange,
  rowsHeights,
  workbookColsWidths,
}: GetWorkBookByPivotTableData) {
  const workbook = XLSX.utils.book_new();

  const workbookSheet = XLSX.utils.aoa_to_sheet(data);

  workbookSheet["!merges"] = mergeRange;
  workbookSheet["!cols"] = workbookColsWidths;
  workbookSheet["!rows"] = rowsHeights;

  XLSX.utils.book_append_sheet(workbook, workbookSheet, "result");

  return workbook;
}

interface GetTableData {
  rowCount: number;
  columnCount: number;
  getRowSection: (colIdx: number, rowIdx: number) => RowSectionElement[];
  visualizationSettings?: PivotTableSettingValues;
}
interface cellData {
  t: XLSX.CellObject["t"];
  v: number | string | undefined;
  z?: string;
}

function getTableData({
  rowCount,
  columnCount,
  getRowSection,
  visualizationSettings,
}: GetTableData) {
  const tableData: DataList = [];
  for (let rowIdx = 0; rowIdx < rowCount; rowIdx++) {
    const rowData: XlsxCellObject[] = [];
    for (let colIdx = 0; colIdx < columnCount; colIdx++) {
      const rowSection = getRowSection(colIdx, rowIdx);
      const rowSectionValues: XlsxCellObject[] = rowSection.map(
        ({
          value,
          rawValue,
          backgroundColor,
          textColor,
          fontStyle,
        }: RowSectionElement) => {
          const settingsBackgroundColor =
            rowIdx % 2 === 0
              ? visualizationSettings?.[
                  "pivot_table.cell_even_background_color"
                ]
              : visualizationSettings?.[
                  "pivot_table.cell_odd_background_color"
                ];

          const cellData: cellData = {
            t: "s",
            v: value,
          };

          if (typeof value === "string") {
            const numberValue = Number(value.replace(/[\s,]+/g, ""));
            !isNaN(numberValue)
              ? ((cellData.t = "n"),
                (cellData.v = rawValue),
                (cellData.z = "#,##0.00"))
              : ((cellData.t = "s"), (cellData.v = value));
          } else {
            typeof value === "number"
              ? ((cellData.t = "n"),
                (cellData.v = rawValue),
                (cellData.z = "#,##0.00"))
              : ((cellData.t = "s"),
                (cellData.v = value === null ? undefined : value));
          }
          return {
            ...cellData,
            s: createXlsxCellStyle({
              fontColor:
                textColor ||
                visualizationSettings?.["pivot_table.cell_text_color"],
              isFontBold:
                fontStyle?.font_bold ||
                visualizationSettings?.["pivot_table.cell_font_bold"],
              isFontItalic:
                fontStyle?.font_italic ||
                visualizationSettings?.["pivot_table.cell_font_italic"],
              fontSize: visualizationSettings?.["pivot_table.cell_font_size"],

              backgroundColor: backgroundColor || settingsBackgroundColor,
              verticalAlignment:
                visualizationSettings?.["pivot_table.cell_vetrical_alignment"],
              horizontalAlignment:
                visualizationSettings?.[
                  "pivot_table.cell_horizontal_alignment"
                ],

              borderColor: visualizationSettings?.["pivot_table.grid_color"],
            }),
          };
        },
      );
      rowData.push(...rowSectionValues);
    }
    tableData.push(rowData);
  }

  const tableRowsHeights = new Array<XLSX.RowInfo>(tableData.length).fill({
    hpx: visualizationSettings?.["pivot_table.cell_height"],
  });

  return { tableData, tableRowsHeights };
}

interface GetTopTitlesData {
  topHeaderItems: LeftHeaderItem[];
  leftHeadersColumnsCount: number;
  columnCount: number;
  visualizationSettings?: PivotTableSettingValues;
}

function getTopTitlesData({
  topHeaderItems,
  leftHeadersColumnsCount,
  columnCount,
  visualizationSettings,
}: GetTopTitlesData) {
  const topTitleTopRow: XlsxCellObject[] = [];
  const topTitleBottomRow: XlsxCellObject[] = [];

  const style = createXlsxCellStyle({
    fontColor: visualizationSettings?.["pivot_table.header_text_color"],
    isFontBold: visualizationSettings?.["pivot_table.title_font_bold"],
    isFontItalic: visualizationSettings?.["pivot_table.title_font_italic"],
    fontSize: visualizationSettings?.["pivot_table.title_font_size"],

    backgroundColor:
      visualizationSettings?.["pivot_table.header_background_color"],
    verticalAlignment:
      visualizationSettings?.["pivot_table.title_vertical_alignment"],
    horizontalAlignment:
      visualizationSettings?.["pivot_table.header_horizontal_alignment"],
    borderColor: visualizationSettings?.["pivot_table.grid_color"],
  });

  const titlesRowsHeights = new Array<XLSX.RowInfo>(topHeaderItems.length).fill(
    {
      hpx: visualizationSettings?.["pivot_table.title_height"],
    },
  );

  topHeaderItems.forEach(element => {
    if (element.depth === 0) {
      topTitleTopRow.push({ v: element.value, s: style });
    } else {
      topTitleBottomRow.push({ v: element.value, s: style });

      if (topTitleBottomRow.length > topTitleTopRow.length) {
        topTitleTopRow.push(null);
      }
    }
  });

  const topTitlesMergeRange: XLSX.Range[] = [];

  const topTitlesList = [topTitleTopRow];
  if (topTitleBottomRow.length > 0) {
    topTitlesList.push(topTitleBottomRow);

    const indent = topTitlesList[0].length / columnCount;
    for (let i = 0; i < topTitlesList[0].length; i += indent) {
      const colStartIdxRange = i + leftHeadersColumnsCount;
      const colEndIdxRange = i + leftHeadersColumnsCount + indent - 1;
      if (colStartIdxRange < colEndIdxRange) {
        topTitlesMergeRange.push({
          s: { r: 0, c: colStartIdxRange },
          e: { r: 0, c: colEndIdxRange },
        });
      }
    }
  }

  return {
    topTitlesList,
    topTitlesMergeRange,
    titlesRowsHeights,
  };
}

interface GetLeftTitlesData {
  topLeftCornerTitles: string[];
  topHeaderRows: number;
  leftHeaderItems: LeftHeaderItem[];
  visualizationSettings?: PivotTableSettingValues;
  getLeftSection: (colIdx: number) => LeftHeaderCellData;
}
function getLeftTitlesData({
  topHeaderRows,
  topLeftCornerTitles,
  leftHeaderItems,
  visualizationSettings,
  getLeftSection,
}: GetLeftTitlesData) {
  const leftTitlesMergeRange: XLSX.Range[] = [];

  const headerStyle = createXlsxCellStyle({
    fontColor: visualizationSettings?.["pivot_table.header_text_color"],
    isFontBold: visualizationSettings?.["pivot_table.title_font_bold"],
    isFontItalic: visualizationSettings?.["pivot_table.title_font_italic"],
    fontSize: visualizationSettings?.["pivot_table.title_font_size"],

    backgroundColor:
      visualizationSettings?.["pivot_table.header_background_color"],
    verticalAlignment:
      visualizationSettings?.["pivot_table.title_vertical_alignment"],
    horizontalAlignment:
      visualizationSettings?.["pivot_table.header_horizontal_alignment"],
    borderColor: visualizationSettings?.["pivot_table.grid_color"],
  });

  const leftTitlesList: DataList = [
    topLeftCornerTitles.map(title => ({ v: title, s: headerStyle })),
  ];
  if (topHeaderRows > 1) {
    leftTitlesList.push(
      topLeftCornerTitles.map((_, i) => {
        leftTitlesMergeRange.push({
          s: { r: 0, c: i },
          e: { r: 1, c: i },
        });
        return null;
      }),
    );
  }

  let currentOffset = 0;
  const colCount = topLeftCornerTitles.length;
  let currentRow: XlsxCellObject[] = new Array(colCount).fill(null);
  const startRow = topHeaderRows;

  leftHeaderItems.forEach((_, index) => {
    const { item, backgroundColor, textColor, fontStyle } =
      getLeftSection(index);
    const nextItem = leftHeaderItems[index + 1];

    const leftStyle = createXlsxCellStyle({
      fontColor:
        textColor ||
        visualizationSettings?.["pivot_table.left_header_text_color"],
      isFontBold:
        fontStyle?.["font_bold"] ||
        visualizationSettings?.["pivot_table.cell_font_bold"],
      isFontItalic:
        fontStyle?.["font_italic"] ||
        visualizationSettings?.["pivot_table.cell_font_italic"],
      fontSize: visualizationSettings?.["pivot_table.cell_font_size"],

      backgroundColor:
        backgroundColor ||
        visualizationSettings?.["pivot_table.left_header_background_color"],
      verticalAlignment:
        visualizationSettings?.["pivot_table.cell_vetrical_alignment"],
      horizontalAlignment:
        visualizationSettings?.["pivot_table.cell_horizontal_alignment"],

      borderColor: visualizationSettings?.["pivot_table.grid_color"],
    });

    const cases = new Map<string, XlsxCellObject>([
      ["type/Integer", { t: "n", v: item.rawValue, z: "0", s: leftStyle }],
      ["type/BigInteger", { t: "n", v: item.rawValue, z: "0", s: leftStyle }],
      [
        "type/DateTime",
        { t: "d", v: item.rawValue, z: "dd mmmm yyyy hh:mm", s: leftStyle },
      ],
      [
        "type/Date",
        { t: "d", v: item.rawValue, z: "dd mmmm yyyy", s: leftStyle },
      ],
      ["type/Text", { t: "s", v: item.value, s: leftStyle }],
    ]);

    if (currentOffset === item.offset) {
      const initialValue = item.column?.base_type || "type/Text";
      const result = cases.get(initialValue);
      currentRow[item.depth] = result
        ? result
        : { t: "s", v: item.value, s: leftStyle };

      const startRowIdxRange = startRow + item.offset;
      const endRowIdxRange = startRow + item.offset + item.span - 1;

      if (startRowIdxRange < endRowIdxRange) {
        leftTitlesMergeRange.push({
          s: { r: startRowIdxRange, c: item.depth },
          e: { r: endRowIdxRange, c: item.depth },
        });
      }
    }

    if (!nextItem || currentOffset < nextItem.offset) {
      let pathLength = 1;
      if (item.path) {
        pathLength = item.path.length;
      }

      if (pathLength < colCount) {
        const rowIdxRange = startRow + item.offset;
        const colStartIdxRange = item.depth;
        const colEndIdxRange = item.depth + colCount - pathLength;

        leftTitlesMergeRange.push({
          s: { r: rowIdxRange, c: colStartIdxRange },
          e: {
            r: rowIdxRange,
            c: colEndIdxRange,
          },
        });
      }

      leftTitlesList.push(currentRow);
      currentOffset = nextItem?.offset || currentOffset + 1;
      currentRow = new Array(colCount).fill(null);
    }
  });

  return { leftTitlesMergeRange, leftTitlesList };
}

interface GetColsWidths {
  visualizationSettings: TVisualizationSettings | undefined;
  resultTable: DataList;
}
function getColsWidths({ visualizationSettings, resultTable }: GetColsWidths) {
  const columnWidthsSettings = visualizationSettings?.[
    "pivot_table.column_widths"
  ] as {
    leftHeaderWidths: number[];
    totalLeftHeaderWidths: number;
    valueHeaderWidths: Record<string, number>;
  };

  const leftHeaderWidths = columnWidthsSettings?.leftHeaderWidths || [];
  const valueHeaderWidths = columnWidthsSettings?.valueHeaderWidths || {};

  const settingsColsWidths: number[] = [
    ...leftHeaderWidths,
    ...new Array(resultTable[0].length - leftHeaderWidths.length).fill(null),
  ];

  for (const key in valueHeaderWidths) {
    settingsColsWidths[leftHeaderWidths.length + Number(key)] =
      valueHeaderWidths[key];
  }

  const workbookColsWidths: XLSX.ColInfo[] = [];
  for (let i = 0; i < resultTable[0].length; i++) {
    if (columnWidthsSettings && settingsColsWidths[i]) {
      workbookColsWidths.push({ wpx: settingsColsWidths[i] });
      continue;
    }

    const firtRowData = (resultTable[0] && resultTable[0][i]) || "";
    const secondRowData = (resultTable[1] && resultTable[1][i]) || "";

    const fontSize = visualizationSettings?.["pivot_table.title_font_size"];
    const firstRowColumnWidth = getTextWidth(firtRowData.toString(), fontSize);
    const secondRowColumnWidth = getTextWidth(
      secondRowData.toString(),
      fontSize,
    );

    workbookColsWidths.push({
      wpx:
        firstRowColumnWidth > secondRowColumnWidth
          ? firstRowColumnWidth
          : secondRowColumnWidth,
    });
  }

  return workbookColsWidths;
}

interface GetFullTable {
  leftTitlesList: DataList;
  topTitlesList: DataList;
  tableData: DataList;
  topHeaderRows: number;
}

function getFullTable({
  leftTitlesList,
  topTitlesList,
  tableData,
  topHeaderRows,
}: GetFullTable) {
  const resultTable = [];
  const resultRowsCount = leftTitlesList.length;
  let row = [];

  for (let rowIdx = 0; rowIdx < resultRowsCount; rowIdx++) {
    row.push(
      ...leftTitlesList[rowIdx],
      ...(rowIdx <= topTitlesList.length - 1
        ? topTitlesList[rowIdx]
        : tableData[rowIdx - topHeaderRows]),
    );
    resultTable.push(row);
    row = [];
  }

  return resultTable;
}
