import { t } from "ttag";

import { color } from "metabase/lib/colors";
import type { WidgetSetting } from "metabase-types/api/widgets/widget";

import {
  CELL_DEFAULT_INDENT,
  TITILE_DEFAULT_INDENT,
} from "../../components/TableInteractive/TableInteractive";

type Alignment = "flex-start" | "center" | "flex-end";
type ShowLineBreak = "show" | "hidden";
type HeaderFile = { fileName: string; data: Uint8Array<ArrayBuffer> };

export type TableSettingsValues = {
  "table.cell_font_size": number;
  "table.cell_font_italic": boolean;
  "table.cell_font_bold": boolean;
  "table.cell_auto_height": boolean;
  "table.cell_height": number;
  "table.cell_horizontal_alignment": Alignment;
  "table.cell_vertical_alignment": Alignment;
  "table.cell_auto_indent": boolean;
  "table.cell_indent_left": number;
  "table.cell_indent_vertical": number;
  "table.cell_transparent": boolean;
  "table.cell_text_color": string;
  "table.cell_odd_background_color": string;
  "table.cell_even_background_color": string;
  "table.cell_background_color_hover": string;
  "table.cell_link_color": string;
  "table.row_clicked_color": string;
  "table.toggle_clicked_row_higlight": boolean;
  "table.title_font_size": number;
  "table.title_font_italic": boolean;
  "table.title_font_bold": boolean;
  "table.title_auto_height": boolean;
  "table.title_height": number;
  "table.title_horizontal_alignment": Alignment;
  "table.title_vertical_alignment": Alignment;
  "table.title_auto_indent": boolean;
  "table.title_indent_left": number;
  "table.titile_indent_vertical": number;
  "table.header_transparent": boolean;
  "table.header_text_color": string;
  "table.header_background_color": string;
  "table.header_sorted_text_color": string;
  "table.header_sort_icon_color": string;
  "table.header_sort_icon_hidden": boolean;
  "table.pin_mode": boolean;
  "table.pinned_columns_count": number;
  "table.pinned_rows_count": number;
  "table.grid_color": string;
  "table.cell_image_size": "100% 100%" | "cover" | "contain";
  "table.title_line_break": ShowLineBreak;
  "table.table_header_export": HeaderFile;
};

export type TableSpecialSettings = {
  "table._cell_color_getter"?: (
    value: any,
    rowIndex: number,
    columnName: string,
  ) => string | null;

  "table._text_color_getter"?: (
    value: any,
    rowIndex: number,
    columnName: string,
  ) => string | null;

  "table._cell_font_style_getter"?: (
    value: any,
    rowIndex: number,
    columnName: string,
  ) => {
    font_bold: boolean;
    font_italic: boolean;
  } | null;
};

type TableSettingsKeys = keyof TableSettingsValues;

export type TableSettings = Record<TableSettingsKeys, WidgetSetting>;

export const TABLE_SETTINGS: TableSettings = {
  "table.cell_font_size": {
    section: t`Cells`,
    title: t`Cell font size`,
    widget: "number",
    default: 12.5,
  },
  "table.cell_font_italic": {
    section: t`Cells`,
    title: t`Cell font italic`,
    widget: "toggle",
    getDefault: (_, vizSettings) => !!vizSettings["table.cell_font_italic"],
  },
  "table.cell_font_bold": {
    section: t`Cells`,
    title: t`Cell font bold`,
    widget: "toggle",
    getDefault: (_, vizSettings) => !!vizSettings["table.cell_font_bold"],
  },
  "table.cell_auto_height": {
    section: t`Cells`,
    title: t`Cell auto height`,
    widget: "toggle",
    default: true,
  },
  "table.cell_height": {
    section: t`Cells`,
    title: t`Cell height`,
    widget: "number",
    getProps: (_, vizSettings) =>
      vizSettings["table.cell_height"] < vizSettings["table.cell_font_size"]
        ? vizSettings["table.cell_font_size"]
        : vizSettings["table.cell_height"],
    isValid: (_, vizSettings) =>
      vizSettings["table.cell_height"] > vizSettings["table.cell_font_size"] &&
      vizSettings["table.cell_height"] <= 1000,
    getDefault: (_, vizSettings) => {
      return vizSettings["cell_auto_height"]
        ? vizSettings["table.cell_font_size"] * CELL_DEFAULT_INDENT
        : vizSettings["table.cell_font_size"];
    },
    readDependencies: ["table.cell_font_size", "table.cell_auto_height"],
    getHidden: (_, vizSettings) => vizSettings["table.cell_auto_height"],
  },
  "table.cell_horizontal_alignment": {
    section: t`Cells`,
    title: t`Cell text horizontal alignment`,
    widget: "select",
    props: {
      options: [
        { name: t`Left`, value: "flex-start" },
        { name: t`Center`, value: "center" },
        { name: t`Right`, value: "flex-end" },
      ],
    },
    default: "flex-start",
  },
  "table.cell_vertical_alignment": {
    section: t`Cells`,
    title: t`Cell text vertical alignment`,
    widget: "select",
    props: {
      options: [
        { name: t`Top`, value: "flex-start" },
        { name: t`Center`, value: "center" },
        { name: t`Bottom`, value: "flex-end" },
      ],
    },
    default: "center",
  },
  "table.cell_auto_indent": {
    section: t`Cells`,
    title: t`Cell auto indent`,
    widget: "toggle",
    default: true,
  },
  "table.cell_indent_left": {
    section: t`Cells`,
    title: t`Cell indent horizontal`,
    widget: "number",
    getProps: (_, vizSettings) =>
      vizSettings["table.cell_indent_left"] < 0
        ? 0
        : vizSettings["table.cell_indent_left"],
    default: CELL_DEFAULT_INDENT,
    isValid: (_, vizSettings) => vizSettings["table.cell_indent_left"] >= 0,
    readDependencies: ["table.cell_auto_indent, table.cell_indent_left"],
    getHidden: (_, vizSettings) => vizSettings["table.cell_auto_indent"],
  },
  "table.cell_indent_vertical": {
    section: t`Cells`,
    title: t`Cell indent vertical`,
    widget: "number",
    default: CELL_DEFAULT_INDENT,
    getProps: (_, vizSettings) => {
      if (vizSettings["table.cell_indent_vertical"] < 0) {
        return 0;
      }
      if (!vizSettings["table.cell_auto_height"]) {
        const newHeight =
          vizSettings["table.cell_indent_vertical"] +
          vizSettings["table.cell_font_size"];
        if (vizSettings["table.cell_height"] < newHeight) {
          return (
            vizSettings["table.cell_height"] -
            vizSettings["table.cell_font_size"]
          );
        }
      }
      return vizSettings["table.cell_indent_vertical"];
    },
    isValid: (_, vizSettings) => {
      if (vizSettings["table.cell_indent_vertical"] < 0) {
        return false;
      }
      if (!vizSettings["table.cell_auto_height"]) {
        return (
          vizSettings["table.cell_height"] >=
          vizSettings["table.cell_indent_vertical"] +
            vizSettings["table.cell_font_size"]
        );
      }
      return true;
    },
    readDependencies: [
      "table.cell_auto_indent",
      "table.cell_height",
      "table.cell_auto_height",
    ],
    getHidden: (_, vizSettings) => vizSettings["table.cell_auto_indent"],
  },

  "table.cell_transparent": {
    section: t`Colors`,
    title: t`Transparent table body background`,
    widget: "toggle",
    default: true,
  },
  "table.cell_text_color": {
    section: t`Colors`,
    title: t`Cell text color`,
    widget: "color",
    getDefault: () => color("text-dark"),
  },
  "table.cell_odd_background_color": {
    section: t`Colors`,
    title: t`Odd rows background color`,
    widget: "color",
    getDefault: () => color("white"),
    getHidden: (_, vizSettings) => vizSettings["table.cell_transparent"],
  },
  "table.cell_even_background_color": {
    section: t`Colors`,
    title: t`Even rows background color`,
    widget: "color",
    getDefault: () => color("white"),
    getHidden: (_, vizSettings) => vizSettings["table.cell_transparent"],
  },
  "table.cell_background_color_hover": {
    section: t`Colors`,
    title: t`Cell hover color`,
    widget: "color",
    getDefault: () => color("bg-medium"),
  },
  "table.cell_link_color": {
    section: t`Colors`,
    title: t`Cell link color`,
    widget: "color",
    getDefault: () => color("brand"),
  },
  "table.row_clicked_color": {
    section: t`Colors`,
    title: t`Row clicked color`,
    widget: "color",
    getDefault: () => color("shadow"),
    getHidden: (_, vizSettings) =>
      !vizSettings["table.toggle_clicked_row_higlight"],
  },
  "table.toggle_clicked_row_higlight": {
    section: t`Colors`,
    title: t`Toggle clicked row higlight`,
    widget: "toggle",
    default: true,
  },

  // table header settings
  "table.title_font_size": {
    section: t`Cells`,
    title: t`Title font size`,
    widget: "number",
    default: 12.5,
  },
  "table.title_font_italic": {
    section: t`Cells`,
    title: t`Title font italic`,
    widget: "toggle",
    getDefault: (_, vizSettings) => !!vizSettings["table.title_font_italic"],
  },
  "table.title_font_bold": {
    section: t`Cells`,
    title: t`Title font bold`,
    widget: "toggle",
    getDefault: (_, vizSettings) => !!vizSettings["table.title_font_bold"],
  },
  "table.title_auto_height": {
    section: t`Cells`,
    title: t`Title auto height`,
    widget: "toggle",
    default: true,
  },
  // since the header looks like a button, its actual size is fontSize * 1.2 + 8.25, its need to calc vertical indents
  "table.title_height": {
    section: t`Cells`,
    title: t`Title height`,
    widget: "number",
    getProps: (_, vizSettings) =>
      vizSettings["table.title_height"] <
      vizSettings["table.title_font_size"] * 1.2 + 8.25
        ? vizSettings["table.title_font_size"] * 1.2 + 8.25
        : vizSettings["table.title_height"],
    getDefault: (card, vizSettings) => {
      const isDashboard = card[0].context && card[0].context === "dashboard";
      const defaultValue = isDashboard
        ? vizSettings["table.title_font_size"] + TITILE_DEFAULT_INDENT * 2
        : vizSettings["table.title_font_size"] * 1.2 +
          8.25 +
          TITILE_DEFAULT_INDENT * 2;
      return vizSettings["table.title_auto_height"]
        ? defaultValue
        : defaultValue - TITILE_DEFAULT_INDENT * 2;
    },
    isValid: (_, vizSettings) =>
      vizSettings["table.title_height"] >
        vizSettings["table.title_font_size"] * 1.2 + 8.25 &&
      vizSettings["table.title_height"] <= 1000,
    readDependencies: ["table.title_font_size", "table.title_auto_height"],
    getHidden: (_, vizSettings) => vizSettings["table.title_auto_height"],
  },
  "table.title_line_break": {
    section: t`Cells`,
    title: t`Title line break`,
    widget: "radio",
    props: {
      options: [
        { name: t`Line break`, value: "show" },
        { name: t`Hidden overflow`, value: "hidden" },
      ],
    },
    default: "hidden",
  },
  "table.title_horizontal_alignment": {
    section: t`Cells`,
    title: t`Title text horizontal alignment`,
    widget: "select",
    props: {
      options: [
        { name: t`Left`, value: "flex-start" },
        { name: t`Center`, value: "center" },
        { name: t`Right`, value: "flex-end" },
      ],
    },
    default: "flex-start",
  },
  "table.title_vertical_alignment": {
    section: t`Cells`,
    title: t`Title text vertical alignment`,
    widget: "select",
    props: {
      options: [
        { name: t`Top`, value: "flex-start" },
        { name: t`Center`, value: "center" },
        { name: t`Bottom`, value: "flex-end" },
      ],
    },
    default: "center",
  },
  "table.title_auto_indent": {
    section: t`Cells`,
    title: t`Title auto indent`,
    widget: "toggle",
    default: true,
  },
  "table.title_indent_left": {
    section: t`Cells`,
    title: t`Title horizontal indent`,
    widget: "number",
    default: 0,
    getProps: (_, vizSettings) =>
      vizSettings["table.title_indent_left"] < 0
        ? 0
        : vizSettings["table.title_indent_left"],
    isValid: (_, vizSettings) => vizSettings["table.title_indent_left"] >= 0,
    readDependencies: ["table.title_auto_indent"],
    getHidden: (_, vizSettings) => vizSettings["table.title_auto_indent"],
  },
  "table.titile_indent_vertical": {
    section: t`Cells`,
    title: t`Title vertical indent`,
    widget: "number",
    default: TITILE_DEFAULT_INDENT,
    getProps: (series, vizSettings) => {
      if (vizSettings["table.titile_indent_vertical"] < 0) {
        return 0;
      }
      if (!vizSettings["table.title_auto_height"]) {
        const newHeight =
          vizSettings["table.titile_indent_vertical"] +
          vizSettings["table.title_font_size"];
        if (vizSettings["table.title_height"] < newHeight) {
          return (
            vizSettings["table.title_height"] -
            vizSettings["table.title_font_size"]
          );
        }
      }
      return vizSettings["table.title_indent_top"];
    },
    isValid: (series, vizSettings) => {
      if (vizSettings["table.titile_indent_vertical"] < 0) {
        return false;
      }
      if (!vizSettings["table.title_auto_height"]) {
        return (
          vizSettings["table.title_height"] >=
          vizSettings["table.titile_indent_vertical"] +
            vizSettings["table.title_font_size"]
        );
      }
      return true;
    },
    readDependencies: [
      "table.title_auto_indent",
      "table.title_height",
      "table.title_auto_height",
    ],
    getHidden: (_, vizSettings) => vizSettings["table.title_auto_indent"],
  },

  "table.header_transparent": {
    section: t`Colors`,
    title: t`Transparent table header background`,
    widget: "toggle",
    default: true,
  },
  "table.header_text_color": {
    section: t`Colors`,
    title: t`Header text color`,
    widget: "color",
    getDefault: () => color("brand"),
  },
  "table.header_background_color": {
    section: t`Colors`,
    title: t`Header background color`,
    widget: "color",
    getDefault: () => color("white"),
    getHidden: (_, vizSettings) => vizSettings["table.header_transparent"],
  },
  "table.header_sorted_text_color": {
    section: t`Colors`,
    title: t`Header sorted text color`,
    widget: "color",
    getDefault: () => color("brand"),
  },
  "table.header_sort_icon_color": {
    section: t`Colors`,
    title: t`Header sort icon color`,
    widget: "color",
    getDefault: () => color("brand"),
    getHidden: (_, vizSettings) => vizSettings["table.header_sort_icon_hidden"],
  },
  "table.header_sort_icon_hidden": {
    section: t`Cells`,
    title: t`Header sort icon hidden`,
    widget: "toggle",
    default: false,
  },

  "table.pin_mode": {
    section: t`Cells`,
    title: t`Cell pin mode`,
    widget: "toggle",
    default: false,
    getHidden: (card, vizSettings) => {
      const isDashboard = card[0].context && card[0].context === "dashboard";
      if (isDashboard) {
        return !vizSettings["card.table_virtualization"];
      }
      return false;
    },
  },
  "table.pinned_columns_count": {
    section: t`Cells`,
    title: t`Pinned columns count`,
    widget: "number",
    default: 1,
    isValid: (_, vizSettings) =>
      vizSettings["table.pinned_columns_count"] < 1
        ? 1
        : vizSettings["table.pinned_columns_count"],
    getHidden: (card, vizSettings) => {
      const isDashboard = card[0].context && card[0].context === "dashboard";
      if (isDashboard) {
        return !vizSettings["card.table_virtualization"];
      }
      return !vizSettings["table.pin_mode"];
    },
  },
  "table.pinned_rows_count": {
    section: t`Cells`,
    title: t`Pinned rows count`,
    widget: "number",
    default: 1,
    isValid: (_, vizSettings) =>
      vizSettings["table.pinned_rows_count"] < 1
        ? 1
        : vizSettings["table.pinned_rows_count"],
    getHidden: (card, vizSettings) => {
      const isDashboard = card[0].context && card[0].context === "dashboard";
      if (isDashboard) {
        return !vizSettings["card.table_virtualization"];
      }
      return !vizSettings["table.pin_mode"];
    },
  },

  "table.grid_color": {
    section: t`Colors`,
    title: t`Grid table color`,
    widget: "color",
    default: color("border"),
  },

  "table.cell_image_size": {
    title: t`Cell image size`,
    section: t`Cells`,
    widget: "select",
    props: {
      options: [
        { name: t`Cover cell`, value: "cover" },
        { name: t`Contain cell`, value: "contain" },
        { name: t`To cell size`, value: "100% 100%" },
      ],
    },

    default: "contain",
  },
};
